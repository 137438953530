import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './assets/js/api'

import {
  Icon,
  Switch,
  Picker,
  Popup,
  IndexBar, 
  IndexAnchor,
  Cell,
  Toast,
  Field,
  RadioGroup, 
  Radio,
  Checkbox, 
  CheckboxGroup,
  DatetimePicker,
  Area,
  Button 
} from 'vant'
Vue.use(Icon).use(Switch).use(Picker).use(Popup).use(IndexBar).use(IndexAnchor).use(Cell).use(Field).use(DatetimePicker).use(Area).use(Button)
.use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup)

router.beforeEach((to , from , next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  next()
})


// import js_md5 from 'js-md5'
// let nowti=Math.round(new Date() / 1000)
// let nowtime=new Date()
// let year,mon,day
// year=nowtime.getFullYear().toString()
// mon=(nowtime.getMonth()+1).toString().length == 1 ? "0"+(nowtime.getMonth()+1).toString() : (nowtime.getMonth()+1).toString()
// day=nowtime.getDate().toString().length == 1 ? "0" + nowtime.getDate().toString() : nowtime.getDate().toString()
// let mowday=year +"-"+ mon +"-"+ day

// let key = js_md5("cdssfjcdjyjdzxxjsyxgs"+nowti+mowday)
// console.log(key);

// var urls="http://47.107.129.103:8101/#/?userid=ejfsbj1211" + "&key=" + key + "&timestamp=" + nowti
// var urls="https://notarization.lvwa18.com/#/?userid=bjfsbj1245" + "&key=" + key + "&timestamp=" + nowti

      





let $usids=window.location.href.split("?userid=")[1]
// let $usids=urls.split("?userid=")[1]
console.log($usids)


var userid=$usids.split("&key=")[0]
var locats=$usids.split("&key=")[1]
var keys=locats.split("&timestamp=")[0]
var timestamp=locats.split("&timestamp=")[1]
axios.getToken({
  // userid，key（小程序对接提供的），timestamp（小程序对接提供），t，s
  userid:userid,
  key:keys,
  timestamp:timestamp
}).then(val=>{
  localStorage.setItem("$token",val.result.token)
  localStorage.setItem("$userinfos",JSON.stringify(val.result))
  console.log("token1:",val)

  if(val.result.is_auth == 0){
    router.push("/") 
  }else{
    router.push("/Home") 
  }
})


Vue.prototype.$Toast = Toast;
Vue.prototype.$axios = axios;



Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



/**
 * 
 * http://47.107.129.103:8101/#?userid=15823064514    H5
 * http://47.107.129.103:8098/Admin/login/index.html   管理系统
 * 
 * 
 * 
 * 
 */