<template>
    <div>
        <ProdctMsg text="上传材料要求完整清晰，封面内页均需上传" border />
        <div class="pagebgcolor"></div>

        <div class="allist" v-for="(item,index) in allists" :key="index">
            <div class="listonetil dis-jasc" @click="mastout(index)">
                <span>{{item.things_name}}</span>
                <img src="../assets/img/next.png" alt="" :class="{xuan:show==index,noxaun:show!=index}">
            </div>

            <div v-show="show==index">

                <!-- 基本信息输入框 -->
                <ul class="userinput">
                    <li v-for="(inputs,i) in item.inputlist" :key="i">
                        <span v-show="inputs.required == 1" class="incolor">*</span><span>{{inputs.plug_name}}:</span>
                        <van-field @blur="inputblur(index,i)" clearable v-model="inputs.vmodel" :type="inputs.intype" :maxlength="inputs.lengths" :placeholder="inputs.plug_describe" show-word-limit />
                    </li>
                </ul>

                <!-- 多行文本 -->
                <ul class="userinput">
                    <li v-for="(inputs,i) in item.textare" :key="i">
                        <span v-show="inputs.required == 1" class="incolor">*</span><span>{{inputs.plug_name}}:</span>
                        <van-field @blur="textblur(index,i)" autosize clearable v-model="inputs.vmodel" type="textarea" maxlength="1000" :placeholder="inputs.plug_describe" show-word-limit />
                    </li>
                </ul>

                <!-- 单选框 -->
                <ul class="radiolist">
                    <li v-for="(rad,i) in item.radios" :key="i">
                        <span v-show="rad.required == 1" class="incolor">*</span><span>{{rad.plug_name}}:</span>
                        <van-radio-group v-model="rad.radio" direction="horizontal" icon-size="18">
                            <van-radio @click="radioclick(index,i)" style="margin:12px 20px 0 .6rem" :name="radone" v-for="radone in rad.arr" :key="radone">{{radone}}</van-radio>
                        </van-radio-group>
                    </li>
                </ul>

                <!-- 复选框 -->
                <ul class="radiolist">
                    <li v-for="(che,i) in item.checkbox" :key="i">
                        <span v-show="che.required == 1" class="incolor">*</span><span>{{che.plug_name}}:</span>
                        <van-checkbox-group v-model="che.check" direction="horizontal" icon-size="18">
                            <van-checkbox @click="checkclick(index,i)" shape="square" style="margin:12px 20px 0 .6rem" :name="chebox" v-for="chebox in che.chelist" :key="chebox">{{chebox}}</van-checkbox>
                        </van-checkbox-group>
                    </li>
                </ul>

                <!-- 时间 -->
                <ul class="userinput">
                    <li v-for="(tim,i) in item.satime" :key="i">
                        <span v-show="tim.required == 1" class="incolor">*</span><span>{{tim.plug_name}}:</span>
                        <van-field v-model="tim.modeltime" disabled placeholder="请选择日期" @click="chosetime(index,i)" />
                    </li>
                </ul>

                <!-- 城市 -->
                <ul class="userinput">
                    <li v-for="(tim,i) in item.sacity" :key="i">
                        <span v-show="tim.required == 1" class="incolor">*</span><span>{{tim.plug_name}}:</span>
                        <van-field v-model="tim.modelcity" disabled placeholder="请选择城市" @click="chosecity(index,i)" />
                    </li>
                </ul>

                <!-- 下拉选择 -->
                <ul class="userinput">
                    <li v-for="(tim,i) in item.selects" :key="i">
                        <span v-show="tim.required == 1" class="incolor">*</span><span>{{tim.plug_name}}:</span>
                        <van-field v-model="tim.vmodel" disabled placeholder="请选择" @click="choseSelet(index,i)" />
                    </li>
                </ul>

                <!-- 上传 -->
                <ul class="uploadimg">
                    <li v-for="(img,i) in item.uploadmore" :key="i">
                        <span v-show="img.required == 1" class="incolor">*</span><span>{{img.plug_name}}:</span>
                        <div class="dis-fw">
                            <p class="adimg" v-for="(its,nu) in img.imgarr" :key="nu">
                                <img :src="its" alt="">
                                <van-icon name="clear" color="#dd6161" size="20" @click="delimg(index,i,nu)" style="position:absolute;top:-6px;right:-6px;z-index:10" />
                            </p>
                            <p class="saimg">
                                <input type="file" accept='image/*' @change="imgchange(index,i)" name="file" :ref="index+'InputFile'+i">
                                <img src="../assets/img/addimg.png" alt="">
                            </p>
                        </div>
                        <p class="imgpra">{{img.plug_describe}}</p>
                    </li>
                </ul>

            </div>
           
        </div>

        <!-- 日期选择弹出层 -->
        <van-popup v-model="showpop" position="bottom"  >
            <van-datetime-picker v-model="currentDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate" @confirm="onconfirm" />
        </van-popup>

        <!-- 下拉弹出框 -->
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onPicker" />
        </van-popup>

        <!-- 省市区 -->
        <van-popup v-model="showcity" position="bottom" >
            <van-area :area-list="areaList" value="510000" @cancel="showcity=false" @confirm="onCity" />
        </van-popup>

        <div style="height:65px"></div>

        <div class="bottombtn" @click="nextonepage">确认提交</div>
    </div>
</template>

<script>
import allcity from "../assets/js/cityList"
import ProdctMsg from "../components/prompt-info"
export default {
    components:{
        ProdctMsg
    },
    data(){
        return {
            currentDate:new Date(),
            minDate:new Date(1920,1,1),
            maxDate:new Date(2050,11,29),
            columns:[],//下拉弹出数据数组
            selec:[],//点击第几个下拉选择
            showPicker:false,//显示下拉选择
            showpop:false,//显示日期
            timenum:[],//点击第几个时间选择
            showcity:false,//显示城市
            areaList:allcity.allcity,//城市列表
            citynum:[],//点击第几个城市选择
            show:0,
            allists:[],
            idsarr:[],//选择的事项id集合
            cannext:true,
            userinfos:{},
            applyid:""
        }
    },
    beforeMount(){

        // for(let i=0;i<this.$axios.mysapply.data.length;i++){
        //     this.idsarr.push(this.$axios.mysapply.data[i].id)
        // }
        this.userinfos=JSON.parse(localStorage.getItem("$userinfos"))
    },
    mounted(){
        this.applyid = this.$route.params.id
        // console.log(this.$route.params.id)
        this.$axios.ordersInfo({
            id:this.$route.params.id,
            token:localStorage.getItem("$token")
        }).then(res=>{
            console.log("申请详情:",res)
            //plug_type 组件类型1姓名 2身份证号 3电话号码 4身份证 5自定义文本框 6自定义下拉框 7单选 8多选 9多行文本 10时间 11上传 12省市区 13证件类型
            //required是是否为必填， 1的话就是必填
            var data=res.result.things
            data.forEach( val => {
                val.inputlist=[]//普通输入框
                val.textare=[]//多行文本
                val.radios=[]//单选框
                val.checkbox=[]//多选框
                val.satime=[]//选择时间
                val.sacity=[]//选择省市区
                val.selects=[]//下拉
                val.uploadmore=[]//图片上传

                var da=val.contents
                for(let i=0;i<da.length;i++){
                    if(da[i].plug_type == 1){//姓名
                        da[i].vmodel=da[i].content
                        da[i].intype="text"
                        da[i].lengths="10"
                        val.inputlist.push(da[i])

                    }else if(da[i].plug_type == 2){//身份证号
                        da[i].vmodel=da[i].content
                        da[i].intype="text"
                        da[i].lengths="18"
                        val.inputlist.push(da[i])

                    }else if(da[i].plug_type == 3){//电话号码
                        da[i].vmodel=da[i].content
                        da[i].intype="number"
                        da[i].lengths="11"
                        val.inputlist.push(da[i])

                    }else if(da[i].plug_type == 4){//上传身份证
                        da[i].imgarr=[]
                        var images=JSON.parse(da[i].content)
                        for(let f=0;f<images.length;f++){
                            da[i].imgarr.push(images[f].url)
                        }
                        val.uploadmore.push(da[i])

                    }else if(da[i].plug_type == 5){//自定义文本框
                        da[i].vmodel=da[i].content
                        da[i].intype="text"
                        da[i].lengths="100"
                        val.inputlist.push(da[i])

                    }else if(da[i].plug_type == 6){//下拉                     asssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
                        da[i].arr=da[i].plug_option.split(",")
                        da[i].vmodel=da[i].content
                        val.selects.push(da[i])

                    }else if(da[i].plug_type == 7){//单选
                        da[i].arr=da[i].plug_option.split(",")
                        da[i].radio=da[i].content
                        val.radios.push(da[i])

                    }else if(da[i].plug_type == 8){//多选
                        da[i].chelist=da[i].plug_option.split(",")
                        da[i].check=da[i].content.split(",")
                        val.checkbox.push(da[i])

                    }else if(da[i].plug_type == 9){//多行文本
                        da[i].vmodel=da[i].content
                        val.textare.push(da[i])
                        
                    }else if(da[i].plug_type == 10){//时间
                        da[i].modeltime=da[i].content
                        val.satime.push(da[i])

                    }else if(da[i].plug_type == 11){//上传
                        da[i].imgarr=[]
                        var imag=JSON.parse(da[i].content)
                        for(let f=0;f<imag.length;f++){
                            da[i].imgarr.push(imag[f].url)
                        }
                        val.uploadmore.push(da[i])

                    }else if(da[i].plug_type == 12){//省市区
                        da[i].modelcity=da[i].content
                        val.sacity.push(da[i])

                    }else if(da[i].plug_type == 13){//证件类型           ssssssssssssssssssssssssssssssssssssss
                        da[i].arr=da[i].plug_option.split(",")
                        da[i].vmodel=da[i].content
                        val.selects.push(da[i])

                    }else if(da[i].plug_type == 14){//非本人手机号
                        da[i].vmodel=da[i].content
                        da[i].intype="number"
                        da[i].lengths="11"
                        val.inputlist.push(da[i])

                    }else if(da[i].plug_type == 15){//固定电话
                        da[i].vmodel=da[i].content
                        da[i].intype="number"
                        da[i].lengths="8"
                        val.inputlist.push(da[i])

                    }
                }
            })

            this.allists=data
        })
    },
    methods:{
        // 输入框失焦
        inputblur(index,i){
            if(this.allists[index].inputlist[i].vmodel){
                var obj={
                things_id:this.allists[index].things_id,
                plug_id:this.allists[index].inputlist[i].id,
                content:this.allists[index].inputlist[i].vmodel
            }
            this.changemsg(obj)
            }
        },
        // 多行文本失焦
        textblur(index,i){
            if(this.allists[index].textare[i].vmodel){
                var obj={
                things_id:this.allists[index].things_id,
                plug_id:this.allists[index].textare[i].id,
                content:this.allists[index].textare[i].vmodel
            }
            this.changemsg(obj)
            }
        },
        // 单选框
        radioclick(index,i){
            var obj={
                things_id:this.allists[index].things_id,
                plug_id:this.allists[index].radios[i].id,
                content:this.allists[index].radios[i].radio
            }
            this.changemsg(obj)
        },
        checkclick(index,i){
            if(this.allists[index].checkbox[i].check.length>0){
                var obj={
                    things_id:this.allists[index].things_id,
                    plug_id:this.allists[index].checkbox[i].id,
                    content:this.allists[index].checkbox[i].check.join(",")
                }
                this.changemsg(obj)
            }
        },
        // 修改信息
        changemsg(msg){
            this.$axios.changeInfo({
                things_id:msg.things_id,
                apply_id:this.applyid,
                plug_id:msg.plug_id,
                content:msg.content,//(修改的内容)
                token:localStorage.getItem("$token")
            }).then(res=>{
                console.log("修改结果",res)
            })
        },
        async nextonepage(){
            await this.testData()

            if(this.cannext){
                this.$Toast({
                    message: '提交成功!',
                    icon: 'passed',
                })
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1500);
            }
        },
        // 验证数据
        testData(){
            this.cannext=true
            this.allists.forEach( val =>{
                for(let i=0;i<val.inputlist.length;i++){
                    if(val.inputlist[i].required == 1){//required=1必填选项
                        if(val.inputlist[i].plug_type == 1){//plug_type=1姓名
                            if(val.inputlist[i].vmodel.length<2){
                                this.$Toast({
                                    message: '请输入'+val.things_name+'下的姓名',
                                    icon: 'warning-o',
                                })
                                this.cannext=false
                                break;
                            }
                        }else if(val.inputlist[i].plug_type == 2){//plug_type=2身份证号
                            var card=this.$axios.validation(4,val.inputlist[i].vmodel)
                            if(!card){
                                this.$Toast({
                                    message:val.things_name + '下的身份证号错误',
                                    icon: 'warning-o',
                                })
                                this.cannext=false
                                break;
                            }
                        }else if(val.inputlist[i].plug_type == 3 || val.inputlist[i].plug_type == 14){//plug_type=3电话号码 plug_type=14非本人电话号码
                            var pho=this.$axios.validation(1,val.inputlist[i].vmodel)
                            if(!pho){
                                this.$Toast({
                                    message: val.things_name + '输入的手机号错误',
                                    icon: 'warning-o',
                                })
                                this.cannext=false
                                break;
                            }
                        }else if(val.inputlist[i].plug_type == 5){//plug_type=5自定义文本框
                            if(val.inputlist[i].vmodel.length==0){
                                this.$Toast({
                                    message: val.things_name + val.inputlist[i].plug_name+ '不能为空!',
                                    icon: 'warning-o',
                                })
                                this.cannext=false
                                break;
                            }
                        }else if(val.inputlist[i].plug_type == 15){//plug_type=15固定电话
                            if(val.inputlist[i].vmodel.length!=8){
                                this.$Toast({
                                    message:'请输入正确的座机号',
                                    icon: 'warning-o',
                                })
                                this.cannext=false
                                break;
                            }
                        }
                    }
                }

                for(let t=0;t<val.textare.length;t++){
                    if(val.textare[t].required == 1){//required=1必填选项
                        if(val.textare[t].vmodel.length==0){
                            this.$Toast({
                                message:val.textare[t].plug_name+ '不能为空!',
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;
                        }
                    }
                }

                for(let c=0;c<val.checkbox.length;c++){
                    if(val.checkbox[c].required == 1){//required=1必填选项
                        if(val.checkbox[c].check.length==0){
                            this.$Toast({
                                message:val.checkbox[c].plug_name + '不能为空!',
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;
                        }
                    }
                }

                for(let d=0;d<val.satime.length;d++){
                    if(val.satime[d].required == 1){//required=1必填选项
                        if(val.satime[d].modeltime.length==0){
                            this.$Toast({
                                message:'请选择'+ val.things_name+'下的日期!',
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;
                        }
                    }
                }

                for(let s=0;s<val.sacity.length;s++){
                    if(val.sacity[s].required == 1){//required=1必填选项
                        if(val.sacity[s].modelcity.length==0){
                            this.$Toast({
                                message:'请选择'+ val.things_name + '下的城市!',
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;
                        }
                    }
                }

                for(let a=0;a<val.selects.length;a++){
                    if(val.selects[a].required == 1){//required=1必填选项
                        if(val.selects[a].vmodel.length==0){
                            this.$Toast({
                                message:"请选择" + val.selects[a].plug_name,
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;
                        }
                    }
                }

                for(let u=0;u<val.uploadmore.length;u++){
                    if(val.uploadmore[u].required == 1){//required=1必填选项
                        if(val.uploadmore[u].imgarr.length==0){
                            this.$Toast({
                                message:"请上传"+ val.things_name + " " + val.uploadmore[u].plug_name,
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;

                        }else if(val.uploadmore[u].plug_type == 4 && val.uploadmore[u].imgarr.length<2){
                            this.$Toast({
                                message:val.things_name + "下的身份证需上传正反两张",
                                icon: 'warning-o',
                            })
                            this.cannext=false
                            break;

                        }
                    }
                }
            })

        },
        // 上传图片
        imgchange(index,num){
            if(this.allists[index].uploadmore[num].imgarr.length == 2 && this.allists[index].uploadmore[num].plug_type == 4){
                this.$Toast({
                    message:"身份证只需上传正反两面!",
                    icon: 'warning-o',
                })
                return;
            }

            this.$Toast.loading({
                message: '上传中...',
                forbidClick: true,
                duration:0
            });
            var InputFile=index+'InputFile'+num
            let fileData = this.$refs[InputFile][0].files[0];

            let formData = new FormData();
            formData.append('images[]', fileData);
            formData.append('token', localStorage.getItem("$token"));
            this.$axios.uploadImg(formData).then(res=> {

                this.$Toast.clear()

                // console.log("返回的数据:",res);
                this.allists[index].uploadmore[num].imgarr.push(res.result[0].url)

                var imgs=[]
                for(let i=0;i<this.allists[index].uploadmore[num].imgarr.length;i++){
                    var osd={
                        url:this.allists[index].uploadmore[num].imgarr[i]
                    }
                    imgs.push(osd)
                }
                
                var obj={
                    things_id:this.allists[index].things_id,
                    plug_id:this.allists[index].uploadmore[num].id,
                    content:JSON.stringify(imgs)
                }
                this.changemsg(obj)
            });
        },
        // 删除图片
        delimg(index,num,nums){
            this.allists[index].uploadmore[num].imgarr.splice(nums,1)

            if(this.allists[index].uploadmore[num].imgarr.length>0){
                var imgs=[]
                for(let i=0;i<this.allists[index].uploadmore[num].imgarr.length;i++){
                    var osd={
                        url:this.allists[index].uploadmore[num].imgarr[i]
                    }
                    imgs.push(osd)
                }
                
                var obj={
                    things_id:this.allists[index].things_id,
                    plug_id:this.allists[index].uploadmore[num].id,
                    content:JSON.stringify(imgs)
                }
                this.changemsg(obj)
            }
        },
        // 展开收起
        mastout(index){
            this.show == index ? this.show = -1 : this.show = index
        },
        // 下拉选择确认按钮
        onPicker(e){
            this.showPicker=false
            var obj=this.allists[this.selec[0]].selects[this.selec[1]]
            obj.vmodel=e
            this.$set(this.allists[this.selec[0]].selects,this.selec[1],obj)

            var objs={
                things_id:this.allists[this.selec[0]].things_id,
                plug_id:this.allists[this.selec[0]].selects[this.selec[1]].id,
                content:this.allists[this.selec[0]].selects[this.selec[1]].vmodel
            }
            this.changemsg(objs)
        },
        // 弹出下拉
        choseSelet(index,num){
            this.selec=[index,num]
            this.showPicker=true
            this.columns=this.allists[index].selects[num].arr
        },
        // 选择日期时间
        onconfirm(e){
            // console.log(e)
            var ti=new Date(e)
            this.showpop=false

            var obj=this.allists[this.timenum[0]].satime[this.timenum[1]]
            obj.modeltime=ti.getFullYear()+"-"+(ti.getMonth()+1)+"-"+ti.getDate()
            this.$set(this.allists[this.timenum[0]].satime,this.timenum[1],obj)

            var objs={
                things_id:this.allists[this.timenum[0]].things_id,
                plug_id:this.allists[this.timenum[0]].satime[this.timenum[1]].id,
                content:this.allists[this.timenum[0]].satime[this.timenum[1]].modeltime
            }
            this.changemsg(objs)
            // this.allists[0].satime[this.timenum].modeltime=ti.getFullYear()+"-"+(ti.getMonth()+1)+"-"+ti.getDate()
        },
        // 弹出时间选择
        chosetime(index,num){
            this.showpop=true
            this.timenum=[index,num]
        },
        // 选择城市
        chosecity(index,num){
            this.citynum=[index,num]
            this.showcity=true
        },
        // 选择城市确认按钮
        onCity(e){
            // console.log(e)
            this.showcity=false
            var obj=this.allists[this.citynum[0]].sacity[this.citynum[1]]
            obj.modelcity=e[0].name + " " + e[1].name + " " + e[2].name
            this.$set(this.allists[this.citynum[0]].sacity,this.citynum[1],obj)

            var objs={
                things_id:this.allists[this.citynum[0]].things_id,
                plug_id:this.allists[this.citynum[0]].sacity[this.citynum[1]].id,
                content:e[0].name + e[1].name + e[2].name
            }
            this.changemsg(objs)
        }
    }
}
</script>

<style lang="less" scoped>
    .incolor{
        color:#fa3534;
        font-size: 0.426667rem;
    }
    .allist{
        width: 10rem;
        margin-bottom: 0.133333rem;
        background-color: #fff;
        img{
            width: 0.266667rem;
            height: 0.466667rem;
        }

        .listonetil{
            width: 100%;
            height: 1.333333rem;
            padding: 0 0.666667rem 0 0.746667rem;
            font-size: 0.426667rem;
            color:#333;
            font-weight: 600;
            border-bottom: 1px solid #F1F1F1;
        }

        // 基本信息输入框
        .userinput{
            width: 10rem;
            li{
                width: 10rem;
                padding: 0.133333rem 0.746667rem;
                padding-bottom: 0;
                border-bottom: 1px solid #F1F1F1;
                color:#333;
                font-size: 0.373333rem;

                .van-cell{
                    border: none;
                }
                input{
                    width: 8.4rem;
                    height: 1rem;
                    line-height: 1rem;
                    font-size: 0.32rem;
                    padding-left: 0.133333rem;
                }
                
            }
        }

        // 单选多选
        .radiolist{
            width: 10rem;
            li{
                width: 100%;
                padding: 0.213333rem 0.746667rem;
                border-bottom: 1px solid #F1F1F1;
                color:#333;
                font-size: 0.32rem;

                span{
                    font-size:0.373333rem;
                }
            }
        }

        // 上传图片
        .uploadimg{
            width: 10rem;
            li{
                width: 100%;
                padding: 0.213333rem 0.746667rem;
                border-bottom: 1px solid #F1F1F1;

                span{
                    font-size: 0.373333rem;
                }

                div{
                    width: 100%;

                    .adimg{
                        width: 2.2rem;
                        height: 2.2rem;
                        margin:.4rem .6rem 0 0;
                        position: relative;
                    }

                    .saimg{
                        margin-top: 0.4rem;
                        width: 2.2rem;
                        height: 2.2rem;
                        position: relative;
                        input{
                            width: 2.2rem;
                            height: 2.2rem;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 10;
                            opacity: 0;
                        }
                        img{
                            margin: 0;
                        }
                    }
                    img{
                        width: 2.2rem;
                        height: 2.2rem;
                        border-radius: 4px;
                    }
                }

                .imgpra{
                    width: 100%;
                    font-size: 0.293333rem;
                    margin-top: 0.186667rem;
                    color:#555
                }
            }
        }
    }

    @keyframes rotaing{
        from{
            transform: rotateZ(0deg);
        }
        to{
            transform: rotateZ(90deg);
        }
    }
    @keyframes rerota{
        from{
            transform: rotateZ(90deg);
        }
        to{
            transform: rotateZ(0deg);
        }
    }

    .xuan{
        animation: rotaing .5s;
        animation-fill-mode:forwards
    }
    .noxaun{
        animation: rerota .5s;
        animation-fill-mode:forwards
    }
</style>


