<template>
    <div>
        <div class="pagebgcolor"></div>

        <div class="myslist" v-for="(item,index) in shandelist" :key="index">
            <div class="listile">
                <div class="dis-alicen">
                    <img src="../assets/img/ticon.png" alt="">
                    <span>{{item.department_name}}</span>
                </div>
                <div>
                    <p><span>申办号：</span>{{item.apply_code}}</p>
                    <p><span>申办时间：</span>{{item.create_time}}</p>
                </div>
            </div>
            
            <div class="listone">
                <p><span>公证名称：</span><span>{{item.thname}}</span></p>
                <p><span>公证数量：</span><span>{{item.nameleng}}项</span></p>
                <p><span>申办用途：</span><span>{{item.purpose}}</span></p>
                <p><span>申办人：</span><span>{{item.name}}</span></p>
                <!-- status: 0 待受理 1 受理中 2 已受理 3 已撤销 -->
                <p><span>申办状态：</span><span :class="{fontcolor:item.status==2,fontc:item.status==3}" style="color:#008FD6">
                    {{item.status == 0 ? "待受理" : item.status == 1 ? "审核中" : item.status == 2 ? "审核通过" : "已撤销"}}</span></p>
            </div>

            <div class="cancels" v-if="item.status==0 || item.status==1">
                <p class="chas" @click="infopage(index)">修改资料</p>
                <p @click="cancels(index)" v-show="item.status==0">取消办理</p>
            </div>
        </div>

        <noData v-show="show" />
        <loadMore :loadings="loads" v-show="shandelist.length>9" />

        <div class="alertpop dis-jacc" v-if="showpro">
            <div class="inbox">
                <div>温馨提示</div>
                <div class="dis-fdjcac">
                    <img src="../assets/img/pro.png" alt="">
                    <p>是否取消该项办理业务</p>
                </div>
                <div class="dis-alicen">
                    <p @click="cancelbtn(0)">取消</p>
                    <p @click="cancelbtn(1)">确认</p>
                </div>
            </div>
        </div>

        <div style="width:100%;height:20px"></div>
    </div>
</template>

<script>
import noData from "../components/hasnodata"
import loadMore from "../components/loading_more"
export default {
    components:{
        noData,
        loadMore
    },
    data(){
        return {
            show:false,
            loads:"查看更多",
            showpro:false,
            shandelist:[],
            token:"",
            cancelId:"",
            reqdata:{
                token:localStorage.getItem("$token"),
                page_size:10,
                page:1
            }
        }
    },
    mounted(){
        this.token=localStorage.getItem("$token")
        this.getmysthing()
  
        window.addEventListener("scroll",this.scrolls)
    },
    destroyed(){
        window.removeEventListener("scroll",this.scrolls)
    },
    methods:{
        scrolls(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTops = document.documentElement.scrollTop || document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeights = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeights = document.documentElement.scrollHeight || document.body.scrollHeight;
            //滚动条到底部的条件
            if(scrollTops+windowHeights==scrollHeights){
                //写后台加载数据的函数
                console.log("距顶部"+scrollTops+"可视区高度"+windowHeights+"滚动条总高度"+scrollHeights);

                this.loads="努力加载中"
                this.reqdata.page=this.reqdata.page+1
                this.getmysthing()
            }  
        },
        // 获取我的办理
        getmysthing(){
            this.$axios.getApplys(this.reqdata).then( res => {
                console.log(res)
                var data = res.result

                data.forEach( val => {
                    var str = ''
                    for(let i=0; i<val.things_name.length; i++){
                        if(i==val.things_name.length-1){
                            str = str + val.things_name[i]
                        }else{
                            str = str + val.things_name[i] + '、'
                        }
                    }
                    val.thname = str
                    val.nameleng = val.things_name.length
                });

                if(this.reqdata.page == 1){
                    this.shandelist.splice(0)
                }

                if(this.reqdata.page == 1 && data.length == 0){
                    this.show=true
                }

                if(data.length > 0){
                    this.loads = "查看更多"
                }else{
                    this.loads = "实在没有了"
                }

                this.shandelist = this.shandelist.concat(data)
            })
        },
        cancels(index){
            this.showpro=true
            this.cancelId=this.shandelist[index].id
        },
        infopage(index){
            this.$router.push(`/modifyInfo/${this.shandelist[index].id}`)
        },
        cancelbtn(num){
            if(num==1){
                this.$axios.delApplys({//参数id（申请单的id）、token
                    id:this.cancelId,
                    token:this.token
                }).then( res =>{
                    if(res.code == 200){
                        this.reqdata.page=1
                        this.getmysthing()
                    }
                })
            }
            this.showpro=false
        }
    }
}
</script>

<style lang="less" scoped>
    .myslist{
        width: 9.253333rem;
        margin: 0.373333rem;
        margin-bottom: 0;
        background-color: #fff;
        border-radius: 5px;

        .listile{
            
            div{
                &:nth-of-type(1){
                    width: 100%;
                    height: 1.413333rem;
                    font-size: 0.426667rem;
                    color:#333;
                    img{
                        width: 0.4rem;
                        height: 0.4rem;
                        margin:0 0.16rem 0 0.506667rem;
                    }
                }
                &:nth-of-type(2){
                    width: 8.213333rem;
                    border-bottom: 1px solid #EEE;
                    margin-left: 0.506667rem;
                    font-size: 0.32rem;
                    color:#999;
                    padding-bottom: 0.346667rem;

                    p{
                        &:nth-of-type(2){
                            margin-top: 0.32rem;
                        }
                    }
                    span{
                        color:#666;
                        width: 1.866667rem;
                        display: inline-block;
                        margin-right: 0.72rem;
                    }
                }
            }
        }

        .listone{
            padding-bottom: 0.506667rem;
            p{
                margin-top: 0.4rem;
                padding: 0 0.506667rem;
                font-size: 0.32rem;
                color:#666;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span{
                    &:nth-of-type(2){
                        width: 5.6rem;
                        display: inline-block;
                        color:#999;
                        text-align: right;
                    }
                }
                .fontcolor{
                    color:#038F37!important;
                }
                .fontc{
                    color:#FC3F42!important;
                }
            }
        }

        .cancels{
            width: 100%;
            height: 1.36rem;
            border-top: 1px solid #EEE;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 0.533333rem;

            p{
                width: 1.706667rem;
                height: 0.64rem;
                border-radius: 4px;
                border: 1px solid #FC3F42;
                color:#FC3F42;
                line-height: 0.64rem;
                text-align: center;
                font-size: 0.32rem;
            }
            .chas{
                border: 1px solid #008FD6;
                color:#008FD6;
                margin-right: 6px;
            }
        }
    }

    .alertpop{
        width:10rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.8);

        .inbox{
            width: 8.266667rem;
            border-radius: 5px;
            background-color: #fff;

            div{
                &:nth-of-type(1){
                    width: 100%;
                    height: 1.253333rem;
                    border-radius: 5px 5px 0 0;
                    background-color:#008FD6;
                    line-height: 1.253333rem;
                    text-align: center;
                    font-size: 0.426667rem;
                    color:#fff
                }
                &:nth-of-type(2){
                    width: 100%;
                    height: 4.026667rem;
                    padding: 0.8rem 0;
                    img{
                        width: 1.413333rem;
                        height: 1.413333rem;
                    }
                    p{
                        font-size: 0.4rem;
                        margin-top: 0.466667rem;
                    }
                }
                &:nth-of-type(3){
                    p{
                        flex: 1;
                        height: 1.253333rem;
                        line-height: 1.253333rem;
                        text-align: center;
                        font-size: 0.4rem;
                        color:#fff;
                        background-color: #C9C9C9;
                        border-radius: 0 0 0 5px;
                        &:nth-of-type(2){
                            background-color: #008FD6;
                            border-radius: 0 0 5px 0;
                        }
                    }
                }
            }
        }
    }
</style>