<template>
    <div>
        <div class="content" v-html="result"></div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            result:""
        }
    },
    mounted(){
        // 获取提醒
        this.$axios.getRemined({
            token:localStorage.getItem("$token")
        }).then(val=>{
            console.log("获取的提醒:",val)
            this.result=val.result
        })
    }
}
</script>
<style lang="less" scoped>
.content{
    z-index: 10;
    width: 9.253333rem;
    border-radius: 10px;
    margin: 0.373333rem;
    background-color: #fff;
    padding:0.373333rem;
    border: 1px solid #DEDEDE;

    
}
</style>