<template>
    <div class="content dis-jacc">
        <div class="innerbox">
            <div>
               <p>{{title}}</p> 
               <p><van-icon name="cross" color="#fff" size="20" @click="hidebox" /></p>
            </div>

            <div>{{contents == "" ? "暂无内容" : contents}}</div>
        </div>
    </div>
</template>

<script>
    export default{
        props:{
            title:String,
            contents:String
        },
        methods:{
            hidebox(){
                this.$emit("onhides")
            }
        }
    }
</script>

<style lang="less" scoped>
    .content{
        width: 10rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.2);
        z-index: 100;

        .innerbox{
            width: 8.666667rem;
            max-height:10.666667rem;
            background-color: #fff;
            border-radius: 0.133333rem;

            div{
                &:nth-of-type(1){
                    width: 100%;
                    height: 1.333333rem;
                    color:#fff;
                    font-size: 0.48rem;
                    font-weight: 600;
                    background-color: #008FD6;
                    border-radius: 0.133333rem 0.133333rem 0 0;
                    position: relative;
                    p{
                        &:nth-of-type(1){
                            width: 100%;
                            height:1.333333rem;
                            line-height: 1.333333rem;
                            text-align: center;
                        }
                        &:nth-of-type(2){
                            position: absolute;
                            top: 0.373333rem;
                            right: 0.4rem;
                        }
                    }
                }
                &:nth-of-type(2){
                    width: 100%;
                    padding: 0.266667rem;
                    overflow-y: auto;
                    max-height: 9.333333rem;
                    min-height: 2.666667rem;
                    font-size: 0.373333rem;
                    line-height: 0.666667rem;
                    color:#333
                }
            }
        }
    }
</style>