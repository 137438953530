import axios from "./axios"

function getToken(data){return axios.post('api/v1/login',data)}//获取token
function getRemined(data){return axios.post('api/v1/index/statement',data)}//获取特别提醒
function upUserinfo(data){return axios.post('api/v1/mine/auth',data)}//完善个人资料
function getMainclass(data){return axios.post('api/v1/index/getcats',data)}//获取首页事项分类
function notRead(data){return axios.post('api/v1/index/mine/unreadnum',data)}//未读消息数量
function personMessages(data){return axios.post('api/v1/index/mine/messages',data)}//个人消息列表
function readmsged(data){return axios.post('api/v1/index/mine/readmessage',data)}//将消息设置为已读
function personInfo(data){return axios.post('api/v1/index/mine/self',data)}//个人基本信息
function getCompaney(data){return axios.post('api/v1/index/department',data)}//获取单位列表
function getMatterall(data){return axios.post('api/v1/index/getthings',data)}//获取事项列表
function getApplys(data){return axios.post('api/v1/index/mine/record',data)}//获取我的办理
function delApplys(data){return axios.post('api/v1/index/order/cancel',data)}//取消我的办理
function getAddress(data){return axios.post('api/v1/index/useplcae',data)}//获取使用地
function submitApply(data){return axios.post('api/v1/index/order/apply',data)}//提交申请
function getUsed(data){return axios.post('api/v1/index/purpose',data)}//获取用途
function getLang(data){return axios.post('api/v1/index/language',data)}//获取语种
function uploadImg(data){return axios.uplodpost('api/v1/index/uploadfiles',data)}//上传图片
function getFroms(data){return axios.post('api/v1/index/plug/index',data)}//根据id获取表单组件配置
function ordersInfo(data){return axios.post('api/v1/index/order/info',data)}//申请详情接口
function changeInfo(data){return axios.post('api/v1/index/order/update',data)}//申请详情修改



// 验证
const validation=function(num,str){
    var rex
    switch(num){
        case 1 :// 验证手机号开头两位和字符串长度
            rex=/^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/
            break;
        case 2 :// 由数字或英文字母组成
            rex=/^[A-Za-z0-9]+$/
            break;
        case 3 :// 13-19位纯数字
            rex=/^([0-9]{1})(\d{12}|\d{13}|\d{14}|\d{15}|\d{16}|\d{17}|\d{18})$/
            break;
        case 4 :// 验证身份证
            // rex=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            rex=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            break;
        case 5 ://禁止使用特殊字符
            rex=/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
            break;
    }

    return rex.test(str)
}

// const $token=""

const mysapply={
    translate:true,//是否翻译 1、翻译；0、不翻译
    language:null,//语种
    use_place:null,//使用地
    purpose:null,//用途
    is_entrust:false,//是否代理，0、不代理、1、代理
    agent_name:"",//代理人
    agent_phone:"",//代理人电话
    agent_idcard:"",//代理人身份证
    department_id:null,//提交单位的id
    data:null//选择的事项列表
}

const saveData=[]

export default{
    mysapply,
    saveData,
    // $token,
    validation,
    getToken,
    getRemined,
    upUserinfo,
    getMainclass,
    notRead,
    personMessages,
    readmsged,
    personInfo,
    getCompaney,
    getMatterall,
    getApplys,
    delApplys,
    getAddress,
    submitApply,
    getUsed,
    getLang,
    getFroms,
    uploadImg,
    ordersInfo,
    changeInfo
}
