<template>
    <div>
        <div class="nodata dis-fdjacc">
            <img src="../assets/img/has.png" alt="">
            <p>暂无数据</p>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>

<style lang="less" scoped>
    .nodata{
        width: 10rem;
        img{
            width: 8.8rem;
            height: 5.2rem;
        }
        p{
            margin-top: 0.266667rem;
            font-size:0.48rem;
            color:#ddd
        }
    }
</style>