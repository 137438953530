<template>
  <div>
    <Promapt text="请选择承办的业务单位" />

    <ul class="unitlist">
        <li class="dis-jasc" v-for="(item,index) in unitlists" :key="index" :class="{savebg:index==bgcolor}" @click="clione(index)">
            <div class="dis-fdjcac">
                <p>单位：{{item.department_name}}</p>
                <p>电话：{{item.phone}}</p>
                <p>地址：{{item.address}}</p>
            </div>
            <div>
                <img :src="item.img" alt="">
            </div>
        </li>
    </ul>

    <div style="width:100%;height:60px"></div>

    <div class="alertpop dis-jacc" v-if="showpro">
        <div class="inbox">
            <div>温馨提示</div>
            <div class="dis-fdjcac">
                <img src="../assets/img/smile.png" alt="">
                <p>您的业务已提交，待承办单位受理后，会根据您的资料联系您，以进行后续缴费与取件等相关事宜！谢谢！</p>
            </div>
            <div @click="cancelbtn">确定</div>
        </div>
    </div>


    <div class="bottombtn" @click="showbtn">业务提交</div>
  </div>
</template>

<script>
import Promapt from "../components/prompt-info"
export default {
    components:{
        Promapt
    },
    data() {
        return {
            showpro:false,
            bgcolor:-1,
            unitlists:[],
            info:{},//基本信息
            saveData:[]
        }
    },
    mounted(){
        this.getponent()
        this.screenData()

        this.info=this.$axios.mysapply
        // console.log(this.info)

        // console.log(this.$axios.saveData)
        // console.log(this.$axios.mysapply.data)
        
    },
    methods:{
        // 整理数据
        screenData(){
            var data=this.$axios.saveData,
                datalist=this.$axios.mysapply.data,
                arr=[]

            for(let i=0;i<datalist.length;i++){
                var obj={
                    things_id:datalist[i].id,//事项的id
                    num:datalist[i].num,//份数
                    data:[]
                }
                arr.push(obj)
            } 

            data.forEach( (val,num) => {
                for(let i=0;i<val.inputlist.length;i++){
                    arr[num].data.push({
                       plug_id:val.inputlist[i].id,//组件的id
                       content:val.inputlist[i].vmodel//用户选择或者填写的内容
                    })
                }

                for(let i=0;i<val.textare.length;i++){
                    arr[num].data.push({
                       plug_id:val.textare[i].id,
                       content:val.textare[i].vmodel
                    })
                }

                for(let i=0;i<val.radios.length;i++){
                    arr[num].data.push({
                       plug_id:val.radios[i].id,
                       content:val.radios[i].radio
                    })
                }
 
                for(let i=0;i<val.checkbox.length;i++){
                    var radio=val.checkbox[i].check.join(",")
                    arr[num].data.push({
                       plug_id:val.checkbox[i].id,
                       content:radio
                    })
                }

                for(let i=0;i<val.satime.length;i++){
                    arr[num].data.push({
                       plug_id:val.satime[i].id,
                       content:val.satime[i].modeltime
                    })
                }

                for(let i=0;i<val.sacity.length;i++){
                    arr[num].data.push({
                       plug_id:val.sacity[i].id,
                       content:val.sacity[i].modelcity
                    })
                }

                for(let i=0;i<val.selects.length;i++){
                    arr[num].data.push({
                       plug_id:val.selects[i].id,
                       content:val.selects[i].vmodel
                    })
                }

                for(let i=0;i<val.uploadmore.length;i++){
                    var imgarr=[]
                    for(let f=0;f<val.uploadmore[i].imgarr.length;f++){
                        imgarr.push({
                            url:val.uploadmore[i].imgarr[f]
                        })
                    }
                    arr[num].data.push({
                       plug_id:val.uploadmore[i].id,
                       content:imgarr
                    })
                }
            })

            this.saveData=arr
        },
        // 选择单位
        clione(index){
            index==this.bgcolor ? this.bgcolor=-1 : this.bgcolor=index
            // console.log(JSON.stringify(this.saveData))
        },
        cancelbtn(){
            this.showpro=false
            this.$router.push("/Home")
        },
        showbtn(){
            if(this.bgcolor==-1){
                this.$Toast({
                    message: '请选择单位!',
                    icon: 'warning-o',
                })
            }else{
                this.$axios.submitApply({
                    token:localStorage.getItem("$token"),
                    translate:this.info.translate ? 1 : 0,//是否翻译 0、不翻译  1、翻译
                    language:this.info.language.language,//语种
                    use_place:this.info.use_place.place,//使用地
                    purpose:this.info.purpose.title,//用途
                    is_entrust:this.info.is_entrust ? 1 : 0,//是否代理，0、不代理  1、代理
                    agent_name:this.info.agent_name,//代理人
                    agent_phone:this.info.agent_phone,//代理人电话
                    agent_idcard:this.info.agent_idcard,//代理人身份证
                    department_id:this.unitlists[this.bgcolor].id,//提交单位的id
                    data:JSON.stringify(this.saveData)//json
                }).then( res => {
                    console.log("提交数据:",res)
                    if(res.code==200){
                        this.showpro=true
                        localStorage.removeItem("mycard")
                    }
                })
            }
        },
        // 获取事项单位
        getponent(){
            this.$axios.getCompaney({
                token:localStorage.getItem("$token")
            }).then(res=>{
                res.result.forEach( val => {
                    val.img = require("../assets/img/savec.png")
                })
                this.unitlists=res.result
                // console.log(res)
            })
        }
    }
};
</script>

<style lang="less" scoped>
.alertpop{
    width:10rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;

    .inbox{
        width: 8.266667rem;
        border-radius: 5px;
        background-color: #fff;

        div{
            &:nth-of-type(1){
                width: 100%;
                height: 1.066667rem;
                border-radius: 5px 5px 0 0;
                background-color:#008FD6;
                line-height: 1.066667rem;
                text-align: center;
                font-size:0.373333rem;
                color:#fff
            }
            &:nth-of-type(2){
                width: 100%;
                font-size: 0.32rem;
                img{
                    width: 1.28rem;
                    height: 1.28rem;
                    margin-top: 0.533333rem;
                }
                p{
                    line-height: 0.613333rem;
                    margin-top:0.4rem;
                    padding: 0 0.48rem;
                    text-indent:2em;
                }
            }
            &:nth-of-type(3){
                width: 3.466667rem;
                height: 0.933333rem;
                line-height: 0.933333rem;
                text-align: center;
                background-color: #008FD6;
                color:#fff;
                font-size: 0.426667rem;
                border-radius: 5px;
                margin: .6rem auto;
            }
        }
    }
}

.unitlist{
    width: 9.253333rem;
    margin: 0 auto;

    li{
        width: 100%;
        height: 2.746667rem;
        margin-bottom: 0.426667rem;
        background-color: #fff;
        border-radius: 5px;
        padding: 0.346667rem 0.533333rem 0.133333rem 0.4rem;
        color:#333;
        box-shadow: 0px 0px 8px rgba(190, 190, 190, 0.16);
        border: 1px solid #EEE;

        div{
            &:nth-of-type(1){
                width: 7.333333rem;
                height: 100%;
                align-items: flex-start;
            }
            &:nth-of-type(2){
                width: .6rem;
                height: .6rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        p{
            width: 100%;
            // margin-top:0.293333rem;
            font-size: 0.373333rem;
        }
    }

    .savebg{
        background-color: #008FD6;
        color:#fff
    }
    
}
</style>
