<template>
    <div>
        <!-- <div class="searchout dis-jacc">
            <div class="searchin dis-jasc">
                <p><img src="../assets/img/big.png" alt="" /></p>
                <p><input type="text" placeholder="搜索使用地" /></p>
                <p>搜索</p>
            </div>
        </div> -->

        <div class="indlist">
            <van-index-bar >
                <div v-for="(item,inde) in indexlist" :key="inde">
                    <van-index-anchor :index="item.til" />
                    <van-cell :title="i.place || i.language" v-for="(i,num) in item.arr" :key="num" @click="clione(i)" />
                </div>
            </van-index-bar>
        </div>

        <div style="width:100%;height:10px"></div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            indexlist:[],
            flag:0//0使用地  1语种
        }
    },
    mounted(){
        console.log(this.$route.params.num)
        if(this.$route.params.num == 0){
            this.flag=0
            this.getaddress()
        }else if(this.$route.params.num == 1){
            this.flag=1
            this.getlang()
        }
        
    },
    methods:{
        // 获取使用地
        getaddress(){
            this.$axios.getAddress({
                token:localStorage.getItem("$token")
            }).then(res=>{
                console.log(res)
                var data=res.result
                var newarr=[]
                for(let a in data){
                    newarr.push({
                        til:a,
                        arr:data[a]
                    })
                }
                this.indexlist=newarr
            })
        },
        // 获取语种
        getlang(){
            this.$axios.getLang({
                token:localStorage.getItem("$token")
            }).then(res=>{
                console.log(res)
                var data=res.result
                var newarr=[]
                for(let a in data){
                    newarr.push({
                        til:a,
                        arr:data[a]
                    })
                }
                this.indexlist=newarr
            })
        },
        clione(msg){
            if(this.flag == 0){
                this.$axios.mysapply.use_place=msg
            }else if(this.flag == 1){
                this.$axios.mysapply.language=msg
            }
            
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less">
.searchout {
  width: 10rem;
  height: 1.493333rem;
  color: #fff;
  border-bottom: 1px solid #f8f8f8;

  .searchin {
    width: 9.146667rem;
    height: 0.853333rem;
    background-color: #ededef;
    border-radius: 0.48rem;
    position: relative;

    p {
      font-size: 0.373333rem;
      &:nth-of-type(1) {
        position: absolute;
        top: 0.24rem;
        left: 0.4rem;
        img {
          width: 0.426667rem;
          height: 0.426667rem;
        }
      }
      &:nth-of-type(2) {
        padding-left: 1.146667rem;
        input {
          width: 5.333333rem;
          height: 0.746667rem;
          line-height: 0.746667rem;
          border: none;
          color: #333;
          background-color: #ededef;
        }
      }
      &:nth-of-type(3) {
        width: 1.706667rem;
        height: 0.853333rem;
        border-radius: 0.48rem;
        line-height: 0.853333rem;
        text-align: center;
        background-color: #008fd6;
      }
    }
  }
}

.van-index-bar__sidebar{
    span{
        margin-top: 6px;
    }
}
</style>