<template>
    <div>
        <div class="pagebgcolor"></div>

        <div class="bgfff">
            <div class="isntran dis-jasc">
                <p>是否翻译</p>
                <van-switch v-model="checked" active-color="#4CD964" inactive-color="#f6f6f6" size="26" @change="trans"/>
            </div>
            <div class="isntran dis-jasc ">
                <p>使用地</p>
                <p class="dis-alicen saves" @click="toindex(0)">
                    <span :class="{notcolor:belong=='请选择'}">{{belong}}</span>
                    <img src="../assets/img/sel.png" alt="">
                </p>
            </div>
            <div class="isntran dis-jasc ">
                <p>用途</p>
                <p class="dis-alicen saves" @click="selects">
                    <span :class="{notcolor:used=='请选择'}">{{used}}</span>
                    <img src="../assets/img/sel.png" alt="">
                </p>
            </div>
            <div class="isntran dis-jasc ">
                <p>语种</p>
                <p class="dis-alicen saves" @click="toindex(1)">
                    <span :class="{notcolor:langui=='请选择'}">{{langui}}</span>
                    <img src="../assets/img/sel.png" alt="">
                </p>
            </div>
            <div class="isntran dis-jasc">
                <p>是否委托办理</p>
                <van-switch v-model="checkeds" active-color="#4CD964" inactive-color="#f6f6f6" size="26" @change="isntrans"/>
            </div>

            <ul class="userinfo" v-show="show">
                <li>
                    <span>姓名</span>
                    <input type="text" v-model="names" placeholder="受托人姓名">
                </li>
                <li>
                    <span>联系方式</span>
                    <input type="number" v-model="phone" placeholder="受托人号码" oninput="if(value.length>11)value=value.slice(0,11)">
                </li>
                <li>
                    <span>身份证号码</span>
                    <input type="text" v-model="ucard" maxlength="18" placeholder="受托人身份证号码">
                </li>
            </ul>
        </div>

        <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" value-key="title" @cancel="showPicker = false" @confirm="onConfirm" />
        </van-popup>

        <div style="height:60px"></div>

        <div class="btntow">
            <div @click="$router.go(-1)">上一步</div>
            <div @click="thenextpage">下一步</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            showPicker:false,//弹出框
            checked:true,//是否翻译
            checkeds:false,//是否委托办理
            show:false,
            used:"请选择",//用途
            langui:"请选择",//语种
            belong:"请选择",//使用地
            names:"",//受托人姓名
            phone:"",//受托人号码
            ucard:"",//受托人身份证号码
            columns:[],
            token:"",
            apply:{}//提交的数据
        }
    },
    mounted(){
        this.token=localStorage.getItem("$token")
        this.apply=this.$axios.mysapply

        this.apply.use_place == null ? this.belong = "请选择" : this.belong = this.apply.use_place.place
        this.apply.language == null ? this.langui = "请选择" : this.langui = this.apply.language.language
        this.apply.purpose == null ? this.used = "请选择" : this.used = this.apply.purpose.title
        this.checked=this.apply.translate
        this.checkeds=this.apply.is_entrust
        this.show=this.apply.is_entrust
        console.log(this.$axios.mysapply.data)
    },
    methods:{
        toindex(num){
            if(num==0){
                this.$router.push(`/indexBar/${0}`)
            }else if(num==1){
                this.$router.push(`/indexBar/${1}`)
            }
        },
        // 下一步
        thenextpage(){
            if(this.$axios.mysapply.use_place == null){
                this.$Toast({
                    message: '请选择使用地',
                    icon: 'warning-o',
                })
                return;
            }

            if(this.$axios.mysapply.purpose == null){
                this.$Toast({
                    message: '请选择用途',
                    icon: 'warning-o',
                })
                return;
            }

            if(this.$axios.mysapply.language == null){
                this.$Toast({
                    message: '请选择语种',
                    icon: 'warning-o',
                })
                return;
            }

            if(this.$axios.mysapply.is_entrust){//代理
                var pho=this.$axios.validation(1,this.phone)
                var card=this.$axios.validation(4,this.ucard)
                if(this.names.length<2){
                    this.$Toast({
                        message: '请输入正确的姓名',
                        icon: 'warning-o',
                    })
                }else if(!pho){
                    this.$Toast({
                        message: '请输入正确手机号',
                        icon: 'warning-o',
                    })
                }else if(!card){
                    this.$Toast({
                        message: '请输入正确身份证号',
                        icon: 'warning-o',
                    })
                }else{
                    this.$axios.mysapply.agent_name=this.names
                    this.$axios.mysapply.agent_phone=this.phone
                    this.$axios.mysapply.agent_idcard=this.ucard
                    this.$router.push('/dataUpload')
                }
            }else{//不代理
                this.$router.push('/dataUpload')
            }
        },
        selects(){
            this.showPicker=true
            this.getallused()
        },
        // 获取用途
        getallused(){
            this.$axios.getUsed({
                token:this.token
            }).then(res=>{
                console.log(res)
                this.columns=res.result
            })
        },
        onConfirm(e){
            this.used=e.title
            this.$axios.mysapply.purpose=e
            this.showPicker=false
        },
        // 是否委托办理
        isntrans(e){
            e ? this.show=true : this.show=false
            this.$axios.mysapply.is_entrust=e
        },
        //是否翻译
        trans(e){
            this.$axios.mysapply.translate=e
        }
    }
}
</script>

<style lang="less" scoped>
    .bgfff{
        width: 10rem;
        background-color: #fff;
        z-index: 1;
        padding: 0 0.906667rem 0 0.72rem;
        font-size: 0.426667rem;
        color:#333;

        .isntran{
            width: 100%;
            height: 1.44rem;
            border-bottom: 1px solid #F1F1F1;

            .saves{
                font-size: 0.373333rem;
                color:#333;
                img{
                    width: 0.213333rem;
                    height: 0.16rem;
                    margin-left: 0.213333rem;
                }
            }

            .notcolor{
                color:#999;
            }
        }

        .userinfo{
            width: 100%;

            li{
                width: 100%;
                height: 1.44rem;
                border-bottom: 1px solid #F1F1F1;
                display: flex;
                justify-content: space-between;
                align-items: center;

                input{
                    width: 5.333333rem;
                    height: 1.066667rem;
                    line-height: 1.066667rem;
                    text-align: right;
                    padding-right: 6px;
                    font-size: 0.373333rem;
                    border: none
                }
            }
        }
    }
</style>