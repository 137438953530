import Vue from 'vue'
import VueRouter from 'vue-router'

import Remind from '../views/remind.vue'
import Useryi from '../views/useryi.vue'
import Home from '../views/Home.vue'
import Search from '../views/search.vue'
import MsgCentter from '../views/msgCentter.vue'
import DataUpload from '../views/dataUpload.vue'
import Mores from '../views/mores.vue'
import MattersOne from '../views/mattersOne.vue'
import TodoBusiness from '../views/todoBusiness.vue'
import Myshandle from '../views/myshandle.vue'
import Personinfo from '../views/personinfo.vue'
import IndexBar from '../views/indexBar.vue'
import SelectUnit from '../views/selectUnit.vue'
import Todolist from '../views/todolist.vue'
import ModifyInfo from '../views/modifyInfo.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Remind',
    component: Remind,
    meta:{
      title:"用户信息"
    }
  },{
    path: '/useryi',
    name: 'Useryi',
    component: Useryi,
    meta:{
      title:"服务协议"
    }
  },{
    path: '/Home',
    name: 'Home',
    component: Home,
    meta:{
      title:"主页"
    }
  },{
    path: '/search',
    name: 'search',
    component:Search,
    meta:{
      title:"搜索"
    }
  },{
    path: '/msgCentter',
    name: 'msgCentter',
    component:MsgCentter,
    meta:{
      title:"消息中心"
    }
  },{
    path: '/dataUpload',
    name: 'DataUpload',
    component:DataUpload,
    meta:{
      title:"资料上传"
    }
  },{
    path: '/mores',
    name: 'Mores',
    component:Mores,
    meta:{
      title:"更多"
    }
  },{
    path: '/mattersOne/:id',
    name: 'MattersOne',
    component:MattersOne,
    meta:{
      title:"业务事项"
    }
  },{
    path: '/todolist',
    name: 'Todolist',
    component:Todolist,
    meta:{
      title:"待办事项"
    }
  },{
    path: '/todoBusiness',
    name: 'TodoBusiness',
    component:TodoBusiness,
    meta:{
      title:"待办业务"
    }
  },{
    path: '/myshandle',
    name: 'Myshandle',
    component:Myshandle,
    meta:{
      title:"我的办理"
    }
  },{
    path: '/personinfo',
    name: 'Personinfo',
    component:Personinfo,
    meta:{
      title:"个人信息"
    }
  },{
    path: '/indexBar/:num',
    name: 'IndexBar',
    component:IndexBar,
    meta:{
      title:"使用地"
    }
  },{
    path: '/selectUnit',
    name: 'SelectUnit',
    component:SelectUnit,
    meta:{
      title:"选择单位"
    }
  },{
    path: '/modifyInfo/:id',
    name: 'ModifyInfo',
    component:ModifyInfo,
    meta:{
      title:"修改事项"
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
