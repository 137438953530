<template>
  <div>
    <div class="searchout dis-jacc">
      <div class="searchin dis-jasc">
        <p><img src="../assets/img/big.png" alt="" /></p>
        <p><input type="text" placeholder="请输入事项名称" v-model="reqdata.keywords" ref="nowinputs" /></p>
        <div><van-button @click="searchbtn" class="serbtn">搜索</van-button></div>
      </div>
    </div>

    <!-- 暂无数据 -->
    <div class="nowNodata" v-show="hasnodata">
      <Promapt text="抱歉，没有相关内容，请通过如下电话咨询" />

      <ul class="callPhone">
        <li class="dis-jasc" v-for="(item, index) in phonelist" :key="index">
          <div class="dis-fdjcac">
            <p>单位：{{ item.department_name }}</p>
            <p>电话：{{ item.phone }}</p>
            <p>地址：{{ item.address }}</p>
          </div>

          <div>
            <a :href="'tel:' + item.phone" class="dis-fdjacc">
              <img src="../assets/img/phone.png" alt="" />
              <p>点击拨打</p>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <!-- 有数据 -->
    <ul class="havedata" v-show="searchdata.length>0">
      <li class="dis-jasc" v-for="(item,index) in searchdata" :key="index">
        <div class="saves dis-alicen" @click="isntsav(index)">
          <p><img :src="item.img" alt=""></p>
          <p class="oneover-hidden">{{item.things_name}}</p>
        </div>
        <div class="addpre dis-alicen">
          <van-icon name="question-o" size="18" color="#999" @click="showbox(index)"/>
        </div>
      </li>
    </ul>

    <AlertBox :title="questil" :contents="quescont" @onhides="onhides" v-show="showalert" />

    <div class="bottombtn" v-show="searchdata.length>0" @click="addcard">确定</div>

  </div>
</template>

<script>
import Promapt from "../components/prompt-info"
import AlertBox from "../components/alertBox";
// import func from '../../vue-temp/vue-editor-bridge';
export default {
  components:{
    Promapt,
    AlertBox
  },
  data() {
    return {
      show:false,
      showalert:false,
      hasnodata:false,//暂无数据
      questil:"",//弹出框标题
      quescont:"",//弹出框内容
      reqdata:{//token、cat_id(分类的id，可选)、keywords(搜索关键字，可选) , 不填参数默认全部,page_size(每页显示？条，默认10),page（当前第几页，默认1）
        token:localStorage.getItem("$token"),
        cat_id:"",
        keywords:"",
        page_size:20,
        page:1
      },
      searchdata:[],//事项列表
      phonelist: [],//单位名称
      cardarr:[],//获取本地缓存
      isntpage:true
    };
  },
  mounted(){
    var lin=localStorage.getItem("mycard")
    if(lin){
      this.cardarr=JSON.parse(lin)
    }

    this.$nextTick(function(){
       this.$refs.nowinputs.focus()
    });
    
    // localStorage.removeItem("mycard")
    
    window.addEventListener("scroll",this.scrolls)
    this.getcompeny()
  },
  watch:{
    isntpage:function(){
      setTimeout(() => {
        this.isntpage = true
      }, 2000);
    }
  },
  destroyed(){
    window.removeEventListener("scroll",this.scrolls)
  },
  methods: {
    onhides(){
      this.showalert=false
    },
    showbox(index){
      this.showalert=true
      this.questil=this.searchdata[index].things_name
      this.quescont=this.searchdata[index].describe
    },
    // 搜索
    searchbtn(){
      var reg=/^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      var isn=reg.test(this.reqdata.keywords)

      if(isn){
        this.$Toast.loading({
            message: '查询中...',
            forbidClick: true,
            duration:0
        });
        this.reqdata.page=1
        this.getAlldata()
        
      }else{
        this.$Toast({
            message: '内容不能包含特殊字符和空格!',
            icon: 'warning-o',
        })
        this.searchdata.splice(0)
      }
    },
    // 确定添加
    addcard(){
      if(this.isntpage){
        this.isntpage = false

        var flag=true//是否有选择的项
        var arr=[]
        for(let i=0;i<this.searchdata.length;i++){
          if(this.searchdata[i].sav){
            flag=false
            if(this.cardarr.length!=0){
              var cans=false//定义缓存数据和添加数据是否重复
              for(let f=0;f<this.cardarr.length;f++){
                if(this.cardarr[f].id==this.searchdata[i].id){
                  cans=true
                }
              }
              if(!cans){
                arr.push(this.searchdata[i])
              }
            }else{
              arr.push(this.searchdata[i])
              console.log(this.searchdata[i].id)
            }
          }
        }
        
        if(flag){
          this.$Toast({
              message: '您还未选择任何事项!',
              icon: 'warning-o'
          })
        }else{
          // this.$Toast({
          //   message: '添加成功，请前往首页待办事项查看!',
          //   icon: 'smile-o',
          //   overlay:true,
          //   forbidClick:true
          // })
          this.cardarr=this.cardarr.concat(arr)
          
          //把选择的事项存在本地
          localStorage.setItem("mycard",JSON.stringify(this.cardarr))

          // setTimeout(()=>{
            this.$router.push("/Home")
          // },1500)
        }
      }
    },
    // 获取数据
    getAlldata(){
      this.$axios.getMatterall(this.reqdata).then(res=>{
        console.log("根据id获取事项:",res)

        if(res.code==200){
          var data=res.result
          data.forEach( val => {
            val.sav=false
            val.num=1
            val.img=require("../assets/img/saveno.png")
          });

          if(this.reqdata.page==1){
            this.searchdata.splice(0)
          }

          if(this.reqdata.page == 1 && data.length == 0){
            this.hasnodata=true
          }else{
            this.hasnodata=false
          }

          this.searchdata=this.searchdata.concat(data)

          this.$Toast.clear()
        }
      })
    },
    scrolls(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTops = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeights = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeights = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if(scrollTops+windowHeights==scrollHeights){
        //写后台加载数据的函数
        console.log("距顶部"+scrollTops+"可视区高度"+windowHeights+"滚动条总高度"+scrollHeights);

        if(!this.hasnodata){
          this.reqdata.page=this.reqdata.page+1
          this.getAlldata()
        }
        
      }  
    },
    // 是否选中
    isntsav(index){
      this.searchdata[index].sav ? this.searchdata[index].img=require("../assets/img/saveno.png") : this.searchdata[index].img=require("../assets/img/save.png")
      this.searchdata[index].sav ? this.searchdata[index].sav=false : this.searchdata[index].sav=true
    },
    // 获取单位名称
    getcompeny(){
      this.$axios.getCompaney({
          token:localStorage.getItem("$token")
      }).then(res=>{
          this.phonelist=res.result
      })
    }
  }
};
</script>

<style lang="less" scoped>
.searchout {
  width: 10rem;
  height: 1.493333rem;
  color: #fff;
  border-bottom: 1px solid #f8f8f8;

  .searchin {
    width: 9.146667rem;
    height: 0.853333rem;
    background-color: #ededef;
    border-radius: 0.48rem;
    position: relative;

    p {
      font-size: 0.373333rem;
      &:nth-of-type(1) {
        position: absolute;
        top: 0.24rem;
        left: 0.4rem;
        img {
          width: 0.426667rem;
          height: 0.426667rem;
        }
      }
      &:nth-of-type(2) {
        padding-left: 1.146667rem;
        input {
          font-size: 0.373333rem;
          width: 5.333333rem;
          height: 0.746667rem;
          line-height: 0.746667rem;
          border: none;
          color: #333;
          background-color: #ededef;
        }
      }
    }
    div{
      .serbtn{
        width: 1.706667rem;
        height: 0.853333rem;
        color:#fff;
        text-align: center;
        border-radius: 0.48rem;
        background-color: #008fd6;
        margin: 0;
        padding: 0;
        line-height: 0.86rem;
        font-size: 0.373333rem;
      }
    }
  }
}

.nowNodata {
  width: 100%;

  .callPhone {
    width: 100%;
    padding: 0 0.373333rem 0.426667rem 0.373333rem;

    li {
      width: 100%;
      height: 2.9rem;
      margin-bottom: 0.426667rem;
      box-shadow: 0px 0px 8px rgba(190, 190, 190, 0.16);
      padding: 0 0.586667rem 0 0.4rem;
      border-radius: 0.133333rem;
      border: 1px solid #eee;

      div {
        &:nth-of-type(1) {
          width: 6.826667rem;
          height: 100%;
          padding:0.25rem 0;
          font-size: 0.373333rem;
          align-items: flex-start;

          // p {
          //   margin-top: 0.266667rem;
          // }
        }
        &:nth-of-type(2) {
          width: 1.333333rem;
          img {
            width: 0.56rem;
            height: 0.56rem;
          }
          p {
            font-size: 0.32rem;
            color: #008fd6;
            margin-top: 0.16rem;
          }
        }
      }
    }
  }
}

.havedata{
  width: 100%;
  padding: 0.32rem 0.373333rem 1.466667rem 0.373333rem;

  li{
    width: 100%;
    height: 1.173333rem;
    border-radius: 5px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #EEE;
    padding: 0 0.133333rem;
    margin-bottom: 0.426667rem;

    .saves{
      font-size: 0.373333rem;
      color:#333;
      
      p{
        &:nth-of-type(1){
          width: 0.666667rem;
          height: 0.426667rem;
          img{
            width: 0.426667rem;
            height: 0.426667rem;
          }
        }
        &:nth-of-type(2){
          width: 5.333333rem;
        }
      }
    }

    .addpre{
      img{
        width: 0.56rem;
        height: 0.56rem;
      }
      p{
        width: 0.72rem;
        font-size: 0.373333rem;
        color:#333;
        text-align: center;
      }
    }
  }
}
</style>