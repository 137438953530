<template>
    <div>
        <div class="pagebgcolor"></div>

        <div v-if="msgnum>0" class="msgnum dis-jacc">
            <p>最近您有{{msgnum}}条新消息</p>
        </div>

        <ul class="msglist">
            <li v-for="(item,index) in msglists" :key="index">
                <div class="dis-jasc">
                    <p class="dis-alicen"><img :src="item.img" alt=""><span :class="{fontcolor:item.is_read==0}">通知消息</span></p>
                    <p>{{item.create_time}}</p>
                </div>
                <div>{{item.content}}</div>
            </li>
        </ul>

        <loadMore :loadings="loads" @lookmores="lookmores" v-show="msglists.length>=10" />
        <noData v-show="show" />

    </div>
</template>

<script>
import loadMore from "../components/loading_more"
import noData from "../components/hasnodata"
export default {
    components:{
        loadMore,
        noData
    },
    data(){
        return {
            show:false,
            msgnum:0,
            token:"",
            loads:"上拉或点击查看更多",
            reqdata:{
                token:localStorage.getItem("$token"),
                page_size:10,
                page:1
            },
            msglists:[]
        }
    },
    mounted(){
        this.token=localStorage.getItem("$token")
        this.notread()
        this.personList()
        this.readed()

        window.addEventListener("scroll",this.scrolls)
    },
    destroyed(){
        window.removeEventListener("scroll",this.scrolls)
    },
    methods:{
        scrolls(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTops = document.documentElement.scrollTop || document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeights = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeights = document.documentElement.scrollHeight || document.body.scrollHeight;
            //滚动条到底部的条件
            if(scrollTops+windowHeights==scrollHeights){
                //写后台加载数据的函数
                console.log("距顶部"+scrollTops+"可视区高度"+windowHeights+"滚动条总高度"+scrollHeights);

                this.loads="努力加载中"
                this.reqdata.page=this.reqdata.page+1
                this.personList()
            }  
        },
        lookmores(){
            this.loads="努力加载中"
            this.reqdata.page=this.reqdata.page+1
            this.personList()
        },
        // 未读消息数量
        notread(){
            this.$axios.notRead({
                token:this.token
            }).then(res=>{
                // console.log(res)
                this.msgnum=res.result
            })
        },
        // /个人消息列表
        personList(){
            this.$axios.personMessages(this.reqdata).then( res => {
                console.log(res)
                var data=res.result
                data.forEach(val => {
                    if(val.is_read==0){
                        val.img=require("../assets/img/selmsg.png")
                    }else{
                        val.img=require("../assets/img/nosel.png")
                    }
                })

                if(this.reqdata.page == 1){
                    this.msglists.splice(0)
                }

                this.msglists=this.msglists.concat(data)

                if(this.reqdata.page == 1 && data.length==0){
                    this.show=true
                }

                if(data.length>0){
                    this.loads="上拉或点击查看更多"
                }else{
                    this.loads="实在没有了"
                }
            })
        },
        // 将消息设置为已读
        readed(){
            this.$axios.readmsged({
                token:this.token
            }).then(res=>{
                console.log(res)
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .msgnum{
        width: 10rem;
        height: 1.6rem;

        p{
            width: 9.253333rem;
            height: 0.906667rem;
            color:#fff;
            background-color: #008FD6;
            line-height: 0.906667rem;
            text-align: center;
            border-radius: 0.666667rem;
            font-size: 0.346667rem;
        }
    }

    .msglist{
        width: 10rem;
        padding: 0 0.373333rem 0.266667rem 0.373333rem;

        li{
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            margin-top: 0.266667rem;
            div{
                &:nth-of-type(1){
                    padding: 0 0.293333rem 0 0.426667rem;
                    height: 1.013333rem;
                    p{
                        &:nth-of-type(1){
                            img{
                                width: 0.266667rem;
                                height: 0.32rem;
                                margin-right: 5px;
                            }
                            span{
                                font-size: 0.32rem;
                                color:#999
                            }
                            .fontcolor{
                                color:#008FD6
                            }
                        }
                        &:nth-of-type(2){
                            font-size: 0.266667rem;
                            color:#999
                        }
                    }
                }
                &:nth-of-type(2){
                    width: 100%;
                    border-top: 1px solid #EEE;
                    font-size: 0.346667rem;
                    color:#333;
                    padding:0.4rem 0.426667rem;
                    line-height: 0.586667rem;
                }
            }
        }
    }
</style>