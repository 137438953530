<template>
    <div>
        <div class="loadmore">
            <p></p>
            <div @click="lookmoer"><img v-show="loadings=='努力加载中'" src="../assets/img/load.png" alt="">{{loadings}}</div>
            <p></p>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        loadings:{
            type:String,
            default:'查看更多'
        }
    },
    methods:{
        lookmoer(){
            this.$emit("lookmores")
        }
    }
}
</script>

<style lang="less" scoped>
    @keyframes rotaing{
        from{
            transform: rotateZ(0deg);
        }
        to{
            transform: rotateZ(360deg);
        }
    }
    .loadmore{
        width: 10rem;
        height: 1.333333rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p{
            width: 2.666667rem;
            height: 1px;
            background-color: #ccc;
        }
        div{
            margin: 0 10px;
            display: flex;
            align-items: center;
            img{
                width: 16px;
                height: 16px;
                margin-right: 6px;
                animation: rotaing .8s linear infinite;
            }
        }
    }
</style>