<template>
    <div>
        <div class="pagebgcolor"></div>

        <div class="fffbox">
            <div class="listone dis-alicen">
                <p>真实姓名：</p>
                <van-field class="inputbox" clearable v-model="username" type="text" maxlength="10" placeholder="请输入真实姓名" />
            </div>
            <div class="listone dis-alicen">
                <p>手机号：</p>
                <van-field class="inputbox" clearable v-model="phones" type="number" maxlength="11" placeholder="请输入手机号" />
            </div>
            <div class="listone listsel dis-alicen">
                <p>性别：</p>
                <div class="inputs dis-alicen">
                    <p class="selec dis-jasc" @click="showPicker=true">
                        <span>{{sex}}</span>
                        <img src="../assets/img/sel.png" alt="">
                    </p>
                </div>
            </div>
            <div class="listone dis-alicen">
                <p>证件号码：</p>
                <van-field class="inputbox" clearable v-model="idcard" type="text" maxlength="18" placeholder="请输入证件号码" />
            </div>
        </div>
        
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
        </van-popup>

        <div class="bottombtn" @click="surebtn">确认提交</div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            showPicker:false,
            columns:["男","女"],
            username:"",
            phones:"",
            sex:"男",//0还未完善资料 1男 2女
            idcard:"",
            token:"",
            userinfo:{}
        }
    },
    mounted(){
        this.token=localStorage.getItem("$token")
        this.userinfo=JSON.parse(localStorage.getItem("$userinfos"))
        this.getsomeinfo()
    },
    methods:{
        // 获取个人基本信息
        getsomeinfo(){
            this.$axios.personInfo({
                token:this.token
            }).then( res => {
                var data = res.result
                this.username = data.name
                this.phones = data.tel
                this.idcard = data.idcard
                data.sex == 2 ? this.sex = "女" : this.sex = "男"
                console.log("个人基本信息:",res)
            })
        },
        // 选择性别
        onConfirm(e){
            console.log(e)
            this.sex=e
            this.showPicker=false
        },
        // 提交个人信息
        surebtn(){
            var card=this.$axios.validation(4,this.idcard)
            var pho=this.$axios.validation(1,this.phones)
            if(this.username.length<2){
                this.$Toast({
                    message: '请输入正确的用户名',
                    icon: 'warning-o',
                })
                return;
            }
            if(!pho){
                this.$Toast({
                    message: '请输入正确的手机号',
                    icon: 'warning-o',
                })
                return;
            }
            if(!card){
                this.$Toast({
                    message: '请输入正确的身份证号',
                    icon: 'warning-o',
                })
                return;
            }
            this.$axios.upUserinfo({
                token:localStorage.getItem("$token"),
                name:this.username,
                tel:this.phones,
                idcard:this.idcard,
                sex:this.sex == "男" ? 1 : 2
            }).then(res=>{
                if(res.result == "ok" && res.code == 200){
                    // this.$Toast({
                    //     message: '提交成功!',
                    //     icon: 'smile-o',
                    // })

                    this.userinfo.name=this.username
                    this.userinfo.idcard=this.idcard
                    this.userinfo.tel=this.phones

                    localStorage.setItem("$userinfos",JSON.stringify(this.userinfo))

                    // setTimeout(()=>{
                        this.$router.push("/Home") 
                    // },1500)
                }
                console.log(res)
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .fffbox{
        width: 9.253333rem;
        margin: 0.373333rem;
        background-color: #fff;
        border-radius: 4px;
        padding: 0 0.506667rem 0 0.373333rem;

        .listone{
            width: 100%;
            height: 1.28rem;
            border-bottom: 1px solid #F1F1F1;

            .inputbox{
                width: 5.333333rem;
            }

            p{
                font-size: 0.426667rem;
                color:#333;
                width: 2.266667rem;
                margin-right: 0.266667rem;
            }

            .inputs{
                width: 5rem;
                height: 1.28rem;
                
                .selec{
                    border: 1px solid #DEDEDE;
                    border-radius: 0.133333rem;
                    width: 1.6rem;
                    height: 0.693333rem;
                    padding: 0 0.186667rem 0 0.266667rem;
                    span{
                        font-size: 0.32rem;
                    }
                    img{
                        width: 0.266667rem;
                        height: 0.213333rem;
                    }
                }
            }
        }
    }

    .van-cell{
        padding: 0;
        border: none;
    }
</style>