<template>
  <div>
    <promptInfo />
    
    <ul class="havedata">
      <li class="dis-jasc" v-for="(item, index) in searchdata" :key="index">
        <div class="saves dis-alicen" @click="isntsav(index)">
          <p><img :src="item.img" alt="" /></p>
          <p class="oneover-hidden">{{ item.things_name }}</p>
        </div>
        <div class="addpre dis-alicen">
          <img src="../assets/img/reduc.png" alt="" @click="redu(index)" />
          <p>{{ item.num }}</p>
          <img style="margin-right: 6px" src="../assets/img/add.png" alt="" @click="add(index)" />
          <van-icon name="question-o" size="18" color="#999" @click="showbox(index)" />
          <van-icon name="clear" size="18" color="#999" style="margin:0 4px" @click="clearone(index)" />
        </div>
      </li>
    </ul>

    <noData v-show="show" />
    <alertBox :title="questil" :contents="quescont" @onhides="onhides" v-show="showalert" />

    <div class="btntow">
        <div @click="$router.go(-1)">上一步</div>
        <div @click="nextone">下一步</div>
    </div>
  </div>
</template>

<script>
// this.reqdata.cat_id=this.$route.params.id


import promptInfo from "../components/prompt-info";
import noData from "../components/hasnodata";
import alertBox from "../components/alertBox";
export default {
  components: {
    promptInfo,
    noData,
    alertBox
  },
  data() {
    return {
      show:false,
      showalert:false,
      questil:"",//弹出框标题
      quescont:"",//弹出框内容
      searchdata:[],
      reqdata:{//不填参数默认全部
        token:localStorage.getItem("$token"),
        cat_id:"",//分类的id，可选
        keywords:"",//搜索关键字，可选
        page_size:10,
        page:1
      }
    }
  },
  watch:{
    searchdata:function(){
      this.searchdata.length==0 ? this.show=true : this.show=false
    }
  },
  mounted(){
    this.$axios.mysapply={
      translate:true,//是否翻译 1、翻译；0、不翻译
      language:null,//语种
      use_place:null,//使用地
      purpose:null,//用途
      is_entrust:false,//是否代理，0、不代理、1、代理
      agent_name:"",//代理人
      agent_phone:"",//代理人电话
      agent_idcard:"",//代理人身份证
      department_id:null,//提交单位的id
      data:null//选择的事项列表
    }

      var ars=localStorage.getItem("mycard")
      if(ars && ars != "[]"){
        this.searchdata=JSON.parse(ars)
      }else{
        this.show=true
      }

  },
  methods:{
    onhides(){
      this.showalert=false
    },
    // 删除某一项
    clearone(index){
      this.searchdata.splice(index,1)
      localStorage.setItem("mycard",JSON.stringify(this.searchdata))
    },
    // 查看说明
    showbox(index){
      this.showalert=true
      this.questil=this.searchdata[index].things_name
      this.quescont=this.searchdata[index].describe
    },
    // 下一步
    nextone(){
      var flag=true,
          arr=[]
      for(let i=0;i<this.searchdata.length;i++){
        console.log(i)
        if(this.searchdata[i].sav){
          arr.push(this.searchdata[i])
          flag=false
        }
      }
      if(flag){
        this.$Toast({
            message: '请至少选择一项!',
            icon: 'warning-o',
        })
      }else{
        this.$axios.mysapply.data=arr
        this.$router.push('/todoBusiness')
      }
    },
    // 是否选中
    isntsav(index){
      this.searchdata[index].sav ? this.searchdata[index].img=require("../assets/img/saveno.png") : this.searchdata[index].img=require("../assets/img/save.png")
      this.searchdata[index].sav ? this.searchdata[index].sav=false : this.searchdata[index].sav=true
    },
    // 减
    redu(index){
      if(this.searchdata[index].num>1){
        this.searchdata[index].num--
      }
    },
    // 加
    add(index){
      this.searchdata[index].num++
    }
  }
};
</script>

<style lang="less" scoped>
.havedata {
  width: 100%;
  padding: 0.32rem 0.373333rem 1.466667rem 0.373333rem;

  li {
    width: 100%;
    height: 1.173333rem;
    border-radius: 5px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 0 0.133333rem;
    margin-bottom: 0.426667rem;

    .saves {
      font-size: 0.373333rem;
      color: #333;

      p {
        &:nth-of-type(1) {
          width: 0.666667rem;
          height: 0.426667rem;
          img {
            width: 0.426667rem;
            height: 0.426667rem;
          }
        }
        &:nth-of-type(2) {
          width: 5rem;
        }
      }
    }

    .addpre {
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
      p {
        width: 0.72rem;
        font-size: 0.373333rem;
        color: #333;
        text-align: center;
      }
    }
  }
}
</style>