<template>
  <div>
    <promptInfo />

    <ul class="havedata">
      <li class="dis-jasc" v-for="(item, index) in searchdata" :key="index">
        <div class="saves dis-alicen" @click="isntsav(index)">
          <p><img :src="item.img" alt="" /></p>
          <p class="oneover-hidden">{{ item.things_name }}</p>
        </div>
        <div class="addpre dis-alicen">
          <img src="../assets/img/reduc.png" alt="" @click="redu(index)" />
          <p>{{ item.num }}</p>
          <img style="margin-right: 6px" src="../assets/img/add.png" alt="" @click="add(index)" />
          <van-icon name="question-o" size="18" color="#999" @click="showbox(index)" />
        </div>
      </li>
    </ul>

    <noData v-show="show" />
    <loadMore :loadings="loads" @lookmores="lookmores" v-show="searchdata.length>=10" />
    <alertBox :title="questil" :contents="quescont" @onhides="onhides" v-show="showalert" />

    <div class="bottombtn mainbtn" @click="addcard">待办事项<span>{{addcards.length}}</span></div>
  </div>
</template>

<script>
import promptInfo from "../components/prompt-info";
import loadMore from "../components/loading_more";
import noData from "../components/hasnodata";
import alertBox from "../components/alertBox";
export default {
  components: {
    promptInfo,
    loadMore,
    noData,
    alertBox
  },
  data() {
    return {
      show:false,//暂无数据
      showalert:false,
      questil:"",//弹出框标题
      quescont:"",//弹出框内容
      searchdata:[],
      loads:"上拉或点击查看更多",
      reqdata:{//token、cat_id(分类的id，可选)、keywords(搜索关键字，可选) , 不填参数默认全部,page_size(每页显示？条，默认10),page（当前第几页，默认1）
        token:localStorage.getItem("$token"),
        cat_id:"",
        keywords:"",
        page_size:10,
        page:1
      },
      addcards:[]//添加的购物车
    }
  },
  mounted(){
    window.addEventListener("scroll",this.scrolls)

    // 获取购物车长度
    var arrs=localStorage.getItem("mycard")
    if(arrs && arrs != "[]"){
      this.addcards=JSON.parse(arrs)
    }

    this.getAlldata()
  },
  destroyed(){
   window.removeEventListener("scroll",this.scrolls)
 },
  methods:{
    // 加载更多
    lookmores(){
      this.loads="努力加载中"
      this.reqdata.page=this.reqdata.page+1
      this.getAlldata()
    },
    // 关闭说明
    onhides(){
      this.showalert=false
    },
    // 查看说明
    showbox(index){
      this.showalert=true
      this.questil=this.searchdata[index].things_name
      this.quescont=this.searchdata[index].describe
    },
    // 确定添加
    addcard(){
      var flag=true//是否有选择的项
      for(let i=0;i<this.searchdata.length;i++){
        if(this.searchdata[i].sav){
          flag=false
        }
      }
      
      if(flag){
        this.$Toast({
            message: '您还未选择任何事项!',
            icon: 'warning-o',
        })
      }else{
        // this.$Toast({
        //   message: '即将进入待办事项!',
        //   icon: 'smile-o',
        // })
        
        // setTimeout(()=>{
          this.$router.push("/todolist")
        // },1900)
      }
    },
    // 获取数据
    getAlldata(){
      this.$axios.getMatterall(this.reqdata).then(res=>{
        console.log("获取所有的事项:",res)

        var data=res.result
        data.forEach( val => {
          if(this.addcards.length>0){
            for(let i=0;i<this.addcards.length;i++){
              if(this.addcards[i].id == val.id){
                val.sav=true
                val.num=this.addcards[i].num
                val.img=require("../assets/img/save.png")
                break;
              }else{
                val.sav=false
                val.num=1
                val.img=require("../assets/img/saveno.png")
              }
            }
          }else{
            val.sav=false
            val.num=1
            val.img=require("../assets/img/saveno.png")
          }
        });

        if(this.reqdata.page==1){
          this.searchdata.splice(0)
        }
        this.searchdata=this.searchdata.concat(data)

        if(this.reqdata.page==1 && data.length==0){
          this.show=true
        }

        if(data.length==0){
          this.loads="实在没有了"
        }else {
          this.loads="上拉或点击查看更多"
        }
      })
    },
    scrolls(){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTops = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeights = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeights = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if(scrollTops+windowHeights==scrollHeights){
        //写后台加载数据的函数
        console.log("距顶部"+scrollTops+"可视区高度"+windowHeights+"滚动条总高度"+scrollHeights);

        this.reqdata.page=this.reqdata.page+1
        this.getAlldata()
      }  
    },
    // 是否选中
    isntsav(index){
      if(this.searchdata[index].sav){
        for(let f=0;f<this.addcards.length;f++){
          if(this.addcards[f].id == this.searchdata[index].id){
            this.addcards.splice(f,1)
          }
        }
        this.searchdata[index].img=require("../assets/img/saveno.png")
        this.searchdata[index].sav=false
      }else{
        this.searchdata[index].img=require("../assets/img/save.png")
        this.searchdata[index].sav=true

        this.addcards.push(this.searchdata[index])
      }

      //把选择的事项存在本地
      localStorage.setItem("mycard",JSON.stringify(this.addcards))
      
    },
    // 减
    redu(index){
      if(this.searchdata[index].num>1){
        this.searchdata[index].num--

        if(this.searchdata[index].sav){
          for(let i=0;i<this.addcards.length;i++){
            if(this.addcards[i].id == this.searchdata[index].id){
              this.addcards[i].num--
            }
          }
        }
      }
    },
    // 加
    add(index){
      this.searchdata[index].num++
      if(this.searchdata[index].sav){
        for(let i=0;i<this.addcards.length;i++){
          if(this.addcards[i].id == this.searchdata[index].id){
            this.addcards[i].num++
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.havedata {
  width: 100%;
  padding: 0.32rem 0.373333rem 1.466667rem 0.373333rem;

  li {
    width: 100%;
    height: 1.173333rem;
    border-radius: 5px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 0 0.133333rem;
    margin-bottom: 0.426667rem;

    .saves {
      font-size: 0.373333rem;
      color: #333;

      p {
        &:nth-of-type(1) {
          width: 0.666667rem;
          height: 0.426667rem;
          img {
            width: 0.426667rem;
            height: 0.426667rem;
          }
        }
        &:nth-of-type(2) {
          width: 5.333333rem;
        }
      }
    }

    .addpre {
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
      p {
        width: 0.72rem;
        font-size: 0.373333rem;
        color: #333;
        text-align: center;
      }
    }
  }
}

.mainbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: inline-block;
      width: 0.533333rem;
      height: 0.533333rem;
      border-radius: 0.266667rem;
      background-color: #FC3F42;
      line-height: 0.56rem;
      text-align: center;
      font-size: 0.32rem;
      margin-left: 0.186667rem;
    }
  }
</style>