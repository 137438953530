<template>
    <div class="promptmsg" :class="{isntbor:border}">
        <img src="../assets/img/prompt.png" alt="">
        <p>{{text}}</p>
    </div>
</template>

<script>
export default {
    props:{
        text:{
            type:String,
            default:"业务第1份为正本，第2份起为副本；如需要副本，数量请选择2份或以上；正本与副本具有同样法律效力"
        },
        border:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<style lang="less" scoped>
    .promptmsg{
        width: 10rem;
        background-color: #fff;
        padding: 0.293333rem 0.8rem 0.266667rem 0.586667rem;

        img{
            width: 1.333333rem;
            height: 0.506667rem;
            border-radius: 4px;
        }

        p{
            margin-top: 0.266667rem;
            font-size: 0.266667rem;
            color:#666
        }
    }
    .isntbor{
        border-bottom: 1px solid #EEE;
    }
</style>