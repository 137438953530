import Vue from 'vue'
import axios from 'axios'
import {Toast} from "vant"
import qs from 'qs'
import js_md5 from 'js-md5'

Vue.use(Toast)

//设置统一路径前缀
// axios.defaults.baseURL = 'http://47.107.129.103:8099/' 
axios.defaults.baseURL = 'https://notarization-server.lvwa18.com' 
// 请求超时时间
axios.defaults.timeout = 15000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        let nowti=Math.round(new Date() / 1000)  //当前时间戳
        let str="t=" + nowti
        let reqarr=config.data.split("&")

        if(config.url == "api/v1/index/order/apply"){
            reqarr.forEach( (val , num) => {
                let a=val.slice(0,4)
                if(a=="data"){
                    reqarr.splice(num,1)
                }
            });
        }
        
        reqarr.push(str)
        // console.log("获取的参数数组:",reqarr);

        let newreq=reqarr.sort()//排序后
        let reqstr=newreq.join("&")
        // console.log("排序后序列化字符串:",reqstr);

        let md5=js_md5(reqstr+"28c8edde3d61a0411511d3b1866f0636")

        config.data=config.data + "&t=" + nowti + "&s=" + md5
        
      return config
    },
    error => Promise.error(error)
)


function post(url,data){
    return new Promise((resolve,reject)=>{
        
        axios.post(url,qs.stringify(data)).then(res=>{
            var datas = res.data
            if(datas.code == 4007){
                Toast({
                    type:"fail",
                    message:"登录过期,请重新使用小程序进行登录"
                })
            }else if(datas.code == 4006){
                Toast({
                    type:"fail",
                    message:"操作未成功"
                })
            }else if(datas.code == 999){
                Toast({
                    type:"fail",
                    message:"请求地址不存在或请求方式（method）错误"
                })
            }else{
                resolve(res.data)
            }
        }).catch(err=>{
            Toast({
                type:"fail",
                message:"请求失败，请检查当前网络"
            })
            reject(err)
        })
    })
}


let uploadFile = axios.create({
    // baseURL: 'http://47.107.129.103:8099/',
    baseURL:'https://notarization-server.lvwa18.com',
    method:"post",
    headers:{'Content-Type': 'multipart/form-data'}
  });
  uploadFile.defaults.timeout = 15000;
  // post请求头
//   uploadFile.defaults.headers.post['Content-Type'] = 'multipart/form-data';

  function uplodpost(url,data){
    return new Promise((resolve,reject)=>{
        
        uploadFile.post(url,data).then(res=>{
            var datas = res.data
            if(datas.code == 4007){
                Toast({
                    type:"fail",
                    message:"登录过期,请重新使用小程序进行登录"
                })
            }else if(datas.code == 4006){
                Toast({
                    type:"fail",
                    message:"操作未成功"
                })
            }else if(datas.code == 999){
                Toast({
                    type:"fail",
                    message:"请求地址不存在或请求方式（method）错误"
                })
            }else{
                resolve(res.data)
            }
        }).catch(err=>{
            Toast({
                type:"fail",
                message:"请求失败，请检查当前网络"
            })
            reject(err)
        })
    })
}

export default {
    post,
    uplodpost
}


