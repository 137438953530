<template>
  <div>
    <!-- <a href="tel:10086">拨打电话</a> -->
    <div class="searchout dis-jacc">
      <div class="searchin dis-jasc" @click="$router.push('/search')">
        <p><img src="../assets/img/big.png" alt=""></p>
        <p>请输入事项名称</p>
        <p><van-button class="serbtn">搜索</van-button></p>
      </div>
    </div>

    <div class="latexlist">
      <div class="latextile dis-jasc">
        <div class="dis-alicen">
          <p></p>
          <p>业务办理</p>
        </div>
        <div class="dis-alicen" @click="$router.push('/mores')">
          <span>更多</span>
          <van-icon name="arrow" color="#008FD6" size="15"/>
        </div>
      </div>

      <ul class="latexone dis-fw">
        <li class="dis-fdjcac" :class="{nomargin:(index+1)%3==0}" v-for="(ite,index) in arr" :key="index" @click="innav(ite.id)">
          <img :src="ite.cat_img" alt="">
          <p>{{ite.cat_name}}</p>
        </li>
      </ul>
    </div>

    <div class="latexlist" style="padding-top:0">
      <div class="latextile dis-alicen">
        <div class="dis-alicen">
          <p></p>
          <p>个人业务</p>
        </div>
      </div>

      <ul class="latexone dis-fwjs" style="justify-content: flex-start">
        <li class="dis-fdjcac" :class="{nomargin:(index+1)%3==0}" v-for="(ite,index) in arr1" :key="index" @click="personinfo(index)">
          <img :src="ite.img" alt="">
          <p>{{ite.text}}</p>
          <p class="positr" v-show="ite.msg>0">{{ite.msg}}</p>
        </li>
      </ul>
    </div>

    <div style="height:80px"></div>

    <div class="bottombtn mainbtn" @click="$router.push('/todolist')">待办事项<span>{{mainsomen}}</span></div>

  </div>
</template>

<script>
export default {
  data(){
    return {
      arr:[],
      arr1:[
            {
              img:require("../assets/img/per1.png"),
              text:"我的办理"
            },
            {
              img:require("../assets/img/per2.png"),
              text:"消息中心",
              msg:"0"
            },
            {
              img:require("../assets/img/per3.png"),
              text:"个人信息"
            }
      ],
      token:"",
      mainsomen:0
    }
  },
  mounted(){
    // localStorage.removeItem("mycard")
    this.token=localStorage.getItem("$token")
    var arrs=localStorage.getItem("mycard")
    if(arrs){
      this.mainsomen=JSON.parse(arrs).length
    }


    var lomain=localStorage.getItem("mainclass")
    // console.log(typeof lomain)
    if(lomain){
      this.arr=JSON.parse(lomain)
    }

    this.getclassend()
    this.notread()
  },
  methods:{
    // 未读消息数量
    notread(){
      this.$axios.notRead({
        token:localStorage.getItem("$token")
      }).then(res=>{
        // console.log(res)
        this.arr1[1].msg=res.result
      })
    },
    // 获取事项分类
    getclassend(){
      this.$axios.getMainclass({
        token:localStorage.getItem("$token")
      }).then(res=>{
        // console.log("首页事项分类",res)
        this.arr=res.result
        if(res.result){
          localStorage.setItem("mainclass",JSON.stringify(res.result))
        }
      })
    },
    innav(id){
      this.$router.push(`/mattersOne/${id}`);
    },
    personinfo(index){
      switch(index){
        case 0 : this.$router.push("/myshandle");break;//我的办理
        case 1 : this.$router.push("/msgCentter");break;//消息中心
        case 2 : this.$router.push("/personinfo");break;//个人信息
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .searchout{
    width: 10rem;
    height: 1.493333rem;
    color:#fff;
    border-bottom: 1px solid #F8F8F8;

    .searchin{
      width: 9.146667rem;
      height: 0.853333rem;
      background-color: #EDEDEF;
      border-radius: 0.48rem;
      position: relative;

      p{
        font-size: 0.373333rem;
        &:nth-of-type(1){
          position: absolute;
          top: 0.24rem;
          left: 0.4rem;
          img{
            width: 0.426667rem;
            height: 0.426667rem;
          }
        }
        &:nth-of-type(2){
          color:#999;
          padding-left: 1.146667rem;
        }
        &:nth-of-type(3){
          width: 1.706667rem;
          height: 0.853333rem;
          .serbtn{
            width: 1.706667rem;
            height: 0.853333rem;
            line-height: 0.86rem;
            color:#fff;
            text-align: center;
            border-radius: 0.48rem;
            background-color: #008fd6;
            margin: 0;
            padding: 0;
            font-size: 0.373333rem;
          }
        }
      }
    }
  }

  .latexlist{
    padding: 0.48rem 0 0.106667rem 0;
    font-size: 0.32rem;
    color:#fff;

    .latextile{
      padding:0 0.613333rem 0 0.373333rem;
      
      div{
        &:nth-of-type(1){
          font-size: 0.4rem;
            font-weight: 600;
            color:#333;
          p{
            &:nth-of-type(1){
              width: 4px;
              height: 0.32rem;
              background-color:#008FD6;
              margin-right: 0.16rem;
            }
          }
        }
        &:nth-of-type(2){
          font-size: 0.346667rem;
          color:#008FD6;
        }
      }
      
    }

    .latexone{
      width: 100%;
      padding: 0.266667rem 0.64rem 0 0.64rem;
      li{
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 0.133333rem;
        background: linear-gradient(90deg, #79BBFB 0%, #5BA2F8 100%);
        position: relative;
        padding: 0.133333rem 0.133333rem 0.16rem 0.133333rem;
        margin:0 .9rem 0.48rem 0;

        img{
          width: 1.226667rem;
          height: 1.226667rem;
        }

        p{
          text-align: center;
        }

        .positr{
          width: 0.48rem;
          height: 0.48rem;
          border-radius: 0.266667rem;
          line-height: 0.48rem;
          text-align: center;
          background-color: #FC3F42;
          position: absolute;
          top: 0.16rem;
          right: 0.186667rem;
        }
      }

      .nomargin{
        margin-right: 0;
      }
    }

    .twomargin{
      justify-content: flex-start;
    }

  }

  .mainbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: inline-block;
      width: 0.533333rem;
      height: 0.533333rem;
      border-radius: 0.266667rem;
      background-color: #FC3F42;
      line-height: 0.56rem;
      text-align: center;
      font-size: 0.32rem;
      margin-left: 0.186667rem;
    }
  }
</style>
